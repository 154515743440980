/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import Seo from '@widgets/Seo'
import React from 'react'

export default function PrivacyPolicyPage({ path }) {
  return (
    <Main variant="compact">
      <Seo pageUrl={path} title="Privacy Policy" />
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy (“Privacy Policy”) describes the information
        collected by Caldera, LLC. (“we,” “us,” or “our”), how that information
        may be used, with whom it may be shared, and your choices about such
        uses and disclosures. By using our website, located at
        https://guesstherose.com (“Website”), our mobile apps, and/or other
        services we provide (collectively, “Guess the Rose”), you agree to the
        practices set forth in this Privacy Policy. If you do not agree with
        this policy, then you must not access or use Guess the Rose.
      </p>
      <p>Information we collect and how we collect it:</p>
      <p>
        Information collected automatically: When you use Guess the Rose, we
        automatically collect and store certain information about your computer
        or mobile device and your activities. This information includes:
      </p>
      <ul>
        <li>
          Mobile Device ID. Your mobile device’s unique ID number (e.g., IMEI,
          AD ID).
        </li>
        <li>
          Geographic Location. Your mobile device’s geographic location when you
          first register for Guess the Rose through our mobile app or when you
          enable location services within the app.
        </li>
        <li>
          Device Information/Specifications. Technical information about your
          computer or mobile device (e.g., type of device, web browser or
          operating system, IP address) to analyze trends, administer the site,
          prevent fraud, track visitor movement in the aggregate, and gather
          broad demographic information.
        </li>
        <li>
          Length and Extent of Usage. How long and in what manner you used Guess
          the Rose and which services and features you used.
        </li>
        <li>
          Cookies: We use “cookies” to keep track of some types of information
          while you are visiting Guess the Rose or using our services. “Cookies”
          are very small files placed on your computer, and they allow us to
          count the number of visitors to our Website and distinguish repeat
          visitors from new visitors. They also allow us and third parties we
          work with to save user preferences, track user trends, and advertise
          to you. We use both session and persistent cookies on our Website;
          session cookies expire at the end of a particular visit to our
          Website, while persistent cookies (also called stored cookies) remain
          active until you disable them through your browser settings, or until
          a pre-set expiration date. We rely on cookies for the proper operation
          of Guess the Rose; therefore if your browser is set to reject all
          cookies, Guess the Rose may not function properly. Users who refuse
          cookies assume all responsibility for any resulting loss of
          functionality with respect to Guess the Rose.
        </li>
        <li>
          Web Beacons: “Web beacons” (also known as “clear gifs” and “pixel
          tags”) are small transparent graphic images that are often used in
          conjunction with cookies in order to further personalize Guess the
          Rose for our users, collect a limited set of information about our
          visitors, and advertise to our users. We may also use web beacons in
          email communications in order to understand the behavior of our
          customers, such as whether an email has been opened or acted upon.
        </li>
      </ul>
      <p>Information you choose to provide:</p>
      <p>
        We require an email address from you when you register with certain
        Guess the Rose apps. We use your email for both, "functions" (e.g.
        sending you your snippet exports), “administrative” (e.g., confirming
        your registration, notifying of matches) and “promotional” (e.g.,
        newsletters, new product offerings, special discounts, event
        notifications, special third- party offers) purposes. Email messages we
        send you may contain code that enables our database to track your usage
        of the e-mails, including whether the e-mail was opened and what links
        (if any) were clicked. You may opt-out of receiving promotional emails
        and other promotional communications from us at any time via the opt-out
        links provided in such communications, or by e-mailing
        guesstherosehq@gmail.com with your specific request. However, we reserve
        the right to send you certain communications relating to Wave Snippets
        such as service announcements, security alerts, update notices, or other
        administrative messages) without affording you the opportunity to opt
        out of receiving such communications.
      </p>
      <p>
        Online Survey Data: We may periodically conduct voluntary member
        surveys. We encourage our members to participate in such surveys because
        they provide us with important information regarding the improvement of
        Guess the Rose. We do not link the survey responses to your name or
        email address, and all responses are anonymous.
      </p>
      <p>How we use the information:</p>
      <p>
        Pursuant to the terms of this Privacy Policy, we may use the information
        we collect from you for the following purposes:
      </p>
      <ol>
        <li>facilitate matches with other Guess the Rose users;</li>
        <li>
          respond to your comments and questions and provide customer service;
        </li>
        <li>
          to tailor and provide communications to you about Guess the Rose and
          related offers, promotions, advertising, news, upcoming events, and
          other information we think will be of interest to you;
        </li>
        <li>monitor and analyze trends, usage and activities;</li>
        <li>investigate and prevent fraud and other illegal activities;</li>
        <li>
          provide, maintain, and improve Guess the Rose and our overall
          business;
        </li>
        <li>
          where we otherwise have a legitimate interest in doing so, for
          example, direct marketing, research (including marketing research),
          network and information security, fraud prevention, and enforcing or
          defending against legal claims; and
        </li>
        <li>where you otherwise consent to such use.</li>
      </ol>
      <p>
        Use for Research. In addition to the uses outlined above, by using Guess
        the Rose, you agree to allow us to anonymously use the information from
        you and your experiences to continue our research into successful
        relationships, including how to create and foster these relationships,
        so that we may continue to improve the Guess the Rose experience. This
        research may be published in our blogs or interviews. However, all of
        your responses will be kept anonymous, and we assure you that no
        personal information will be published.
      </p>
      <h2>Sharing Your Information</h2>
      <p>
        The information we collect is used to provide and improve the content
        and the quality of Guess the Rose, and without your consent we will not
        otherwise share your personal information to/with any other parties for
        commercial purposes, except: (a) to provide Guess the Rose, (b) when we
        have your permission, or (c) or under the following instances:
      </p>

      <p>
        Service Providers. We may share your information with our third-party
        service providers that support various aspects of our business
        operations (e.g., marketing and analytics providers, security and
        technology providers, and payment processors). We may use Stripe®, to
        process subscription payments, and therefore provide them with the
        personal data required to charge your credit card.
      </p>
      <p>
        Legal Disclosures and Business Transfers. We may disclose any
        information without notice or consent from you: (a) in response to a
        legal request, such as a subpoena, court order, or government demand;
        (b) to investigate or report illegal activity; or (c) to enforce our
        rights or defend claims. We may also transfer your information to
        another company in connection with a merger, financing due diligence,
        corporate restructuring, sale of any or all of our assets, or in the
        event of bankruptcy.
      </p>
      <p>
        Aggregate Data. We may combine non-PII we collect with additional
        non-PII collected from other sources for our blog. We also may share
        aggregated, non-PII with third parties, including advisors, advertisers
        and investors, for the purpose of conducting general business analysis.
      </p>
      <p>
        We recommend and encourage you (and all our members) to think carefully
        about the information you disclose about yourself. We also do not
        recommend that you put email addresses, URLs, instant messaging details,
        phone numbers, full names or addresses, credit card details, national
        identity numbers, drivers' licence details and other sensitive
        information on your profile which is open to abuse and misuse.
      </p>
      <h2>Referring Your Friends</h2>
      <p>
        We encourage you to refer your friends to Guess the Rose by sending us
        your friends’ email addresses or phone numbers. We will keep this
        information in our database, and enable you to send these friends a one-
        time email or text message from your device containing your name and
        inviting them to visit our site. This email will also include
        instructions on how to opt out and unsubscribe from our email list. You
        agree that you will not abuse this feature by entering the email
        addresses or phone numbers of those individuals who would not be
        interested in Guess the Rose.
      </p>
      <h2>Updating or Removing Account Information</h2>
      <p>
        You may review or edit your profile as you wish, by logging into your
        Guess the Rose account using the information supplied during the
        registration process. If you would like to have us delete your account
        information, we may do so by deactivating your account first and then
        permanently deleting your account. Where you have consented to our use
        of your personal information, you may withdraw your consent at any time.
        Notwithstanding the foregoing, we may continue to contact you for the
        purpose of communicating information relating to your request for Guess
        the Rose services, or to respond to any inquiry or request made by you,
        as applicable. To opt out of receiving message concerning Guess the
        Rose, you must cease requesting and/or utilizing services from Guess the
        Rose, and cease submitting inquiries to Guess the Rose, as applicable.
      </p>
      <h2>Individual Rights in the European Economic Area</h2>
      <p>
        If you are located in the European Economic Area, you may seek
        confirmation regarding whether Guess the Rose is processing personal
        information about you, request access to your personal information and
        ask that we correct, amend, delete, or restrict processing of your
        personal information. In addition, you may object to Guess the Rose’s
        processing of your personal information at any time. However doing so
        may impact your use of Guess the Rose.
      </p>
      <p>
        If applicable law provides you the right to request access to, (receive)
        port, object to or restrict processing, seek rectification or request
        erasure of personal information held about you by Guess the Rose, you
        may do so, as applicable, through the controls in the Guess the Rose
        application or by contacting Guess the Rose. To protect your privacy,
        Guess the Rose will endeavor to take commercially reasonable steps to
        verify your identity before granting access to or making any changes to
        your personal information.
      </p>
      <p>
        Although Guess the Rose makes good faith efforts to provide users with
        access to their personal information, there may be circumstances in
        which Guess the Rose is unable to provide access, including but not
        limited to: where the information contains legal privilege, would
        compromise others’ privacy or other legitimate rights, where the burden
        or expense of providing access would be disproportionate to the risks to
        the Individual’s privacy in the case in question, where the request is
        manifestly unfounded or excessive, or where the information sought is
        commercially proprietary.
      </p>
      <h2>Third Party Sites</h2>
      <p>
        Guess the Rose may contain links to other websites and services. If you
        choose to click on a third party link, you will be directed to that
        third party’s website or service. The fact that we link to a website or
        service is not an endorsement, authorization or representation of our
        affiliation with that third party, nor is it an endorsement of their
        privacy or information security policies or practices. We do not
        exercise control over third party websites or services. These third
        parties may place their own cookies or other files on your computer,
        collect data or solicit personal information from you. Other websites
        and services follow different rules regarding the use or disclosure of
        the personal information you submit to them. We encourage you to read
        the privacy policies or statements of the other websites and services
        you visit.
      </p>
      <h2>Age Restriction</h2>
      <p>
        We do not target persons under 18 years of age to use Guess the Rose,
        and we do not knowingly collect information from persons under the age
        of 16. If you are a parent or legal guardian who discovers that your
        child has provided us with information without your consent, you may
        contact us at guesstherosehq@gmail.com, and we will promptly delete such
        information from our files.
      </p>
      <h2>Data Retention</h2>
      <p>
        Guess the Rose retains the personal information we receive as described
        in this Privacy Policy for as long as you use our services or as
        necessary to fulfill the purpose(s) for which it was collected, provide
        our services, resolve disputes, establish legal defenses, conduct
        audits, pursue legitimate business purposes, enforce our agreements, and
        comply with applicable laws.
      </p>
      <h2>Securing Your Personal Information</h2>
      <p>
        We take steps to ensure that your information is treated securely and in
        accordance with this Privacy Policy. Unfortunately, the Internet cannot
        be guaranteed to be 100% secure, and we cannot ensure or warrant the
        security of any information you provide to us. We do not accept
        liability for unintentional disclosure.
      </p>
      <p>
        By providing personal information to us, you agree that we may
        communicate with you electronically regarding security, privacy, and
        administrative issues relating to your use of Guess the Rose. If we
        learn of a security system’s breach, we may attempt to notify you
        electronically by posting a notice on the Site or sending an e-mail to
        you. You may have a legal right to receive this notice in writing.
      </p>
      <h2>Using Guess the Rose from outside the United States</h2>
      <p>
        If you are visiting Guess the Rose from outside the United States,
        please be aware that your information may be transferred to, stored, and
        processed globally, including in the United States where our servers are
        located and our central database is operated. By using our services, you
        understand and agree that your information may be transferred to our
        facilities and those third parties with whom we share it for processing
        as described in this Privacy Policy. Where required by law,
        international transfers of personal information will be supported by an
        adequacy mechanism.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may change this Privacy Policy at any time and from time to time. The
        most recent version of the Privacy Policy is reflected by the version
        date located at the bottom of this Privacy Policy. All such updates and
        amendments are effective immediately upon notice thereof, which we may
        give by any means, including, but not limited to, by posting a revised
        version of this Privacy Policy or other notice on the Website. You
        should view this Privacy Policy often to stay informed of changes that
        may affect you, as your continued use of the Website signifies your
        continuing consent to be bound by this Privacy Policy. We expressly
        reserve the right to make any changes to this Privacy Policy at any
        time, without prior notice to you. The version of this Privacy Policy
        posted here on each respective date you visit the Website shall be the
        Privacy Policy applicable to your access and use of the Website on that
        date. Our electronically or otherwise properly stored copies of this
        Privacy Policy shall be deemed to be the true, complete, valid,
        authentic, and enforceable copy of the version of this Privacy Policy
        which were in force on each respective date you visited the Website.
        This Privacy Policy is not intended to and does not create any
        contractual or other legal right in or on behalf of any party other than
        Wave Snippets.
      </p>
      <h2>How to Contact Us</h2>
      <p>
        If you have any questions about our privacy practices, this Privacy
        Policy, or how to lodge a complaint with the appropriate authority,
        please contact us by email at guesstherosehq@gmail.com.
      </p>
      <p>
        This policy is effective as of <b>26 July 2020</b>.
      </p>
    </Main>
  )
}
